import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Loader } from "./Loader";
import Faq from "./Pages/Faq";
import Form from "./Form";

const Home = lazy(() => import("./Pages/Home"));
const Layout = lazy(() => import("./Layout"));
const NavigateNext = lazy(() => import("./Pages/NavigateNext"));
const Industries = lazy(() => import("./Pages/Industries"));
const Platforms = lazy(() => import("./Pages/Platforms"));
const Services = lazy(() => import("./Pages/Services"));
const AboutUs = lazy(() => import("./Pages/About"));
const Blogdetails = lazy(() => import("./Pages/BlogDetails"));
const Training = lazy(() => import("./Pages/Training"));
const Content = lazy(() => import("rsuite"));
const Contact = lazy(() => import("./Pages/Contact"));
const Blog = lazy(() => import("./Pages/Blog"));
const Reeact = lazy(() => import("./component/reeact"));
const Fullstack = lazy(() => import("./component/fullstack"));
const Careers = lazy(() => import("./Pages/Careers"));
const Products = lazy(() => import("./Pages/Project"));
const Subsiders = lazy(() => import("./Pages/Subsiders"));
const Cloud = lazy(() => import("./component/cloud"));
const Devops = lazy(() => import("./component/devops"));
const Header = lazy(() => import("./Shared/Header"));
const Footer = lazy(() => import("./Shared/Footer"));
const PrivacyPolicy = lazy(() => import("./Pages/Privacy"));
const Awws = lazy(() => import("./component/awws"));
const Digital = lazy(() => import("./component/digital"));
const Mobile = lazy(() => import("./component/mobile "));
const PHP = lazy(() => import("./component/php"));
const Register = lazy(() => import("./component/register "));
const Payment = lazy(() => import("./component/payment"));
const ConsentBanner = lazy(() => import("./ConsentBanner"));

function App() {
  return (
    <>
      <BrowserRouter>
        <ConsentBanner />
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Layout component={<Home />} />} />
            <Route
              path="/navigatenext"
              element={<Layout component={<NavigateNext />} />}
            />{" "}
            <Route
              path="/register-for-fullstack"
              element={<Layout component={<Fullstack />} />}
            />{" "}
            <Route
              path="/register"
              element={<Layout component={<Register />} />}
            />
            <Route
              path="/register-for-cloude-computing"
              element={<Layout component={<Cloud />} />}
            />
            <Route
              path="/payment"
              element={<Layout component={<Payment />} />}
            />
            <Route
              path="/register-for-digital-marketing"
              element={<Layout component={<Digital />} />}
            />
            <Route
              path="/register-for-mobile-apps"
              element={<Layout component={<Mobile />} />}
            />
            <Route
              path="/register-for-php"
              element={<Layout component={<PHP />} />}
            />
            <Route
              path="/register-for-devops"
              element={<Layout component={<Devops />} />}
            />
            <Route
              path="/register-for-react"
              element={<Layout component={<Reeact />} />}
            />
            <Route
              path="/register-for-aws"
              element={<Layout component={<Awws />} />}
            />
            <Route
              path="/industries"
              element={<Layout component={<Industries />} />}
            />
            <Route
              path="/aboutus"
              element={<Layout component={<AboutUs />} />}
            />
            <Route
              path="/services"
              element={<Layout component={<Services />} />}
            />
            <Route
              path="/blogdetails/:id/:slug"
              element={<Layout component={<Blogdetails />} />}
            />
            <Route path="/blog" element={<Layout component={<Blog />} />} />
            <Route
              path="/contact"
              element={<Layout component={<Contact />} />}
            />
            <Route
              path="/career"
              element={<Layout component={<Careers />} />}
            />
            <Route
              path="/project"
              element={<Layout component={<Products />} />}
            />
            <Route
              path="/subsiders"
              element={<Layout component={<Subsiders />} />}
            />
            <Route
              path="/training"
              element={<Layout component={<Training />} />}
            />
            <Route
              path="/privacy-policy"
              element={<Layout component={<PrivacyPolicy />} />}
            />
            <Route
              path="/faq"
              element={<Layout component={<Faq />} />}
            />
            <Route
              path="/form"
              element={<Layout component={<Form />} />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
