import * as React from "react";
import {
  ContentCopy,
  Facebook,
  Share,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import { Box, Button, Divider, Modal } from "@mui/material";
import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import CustomButton from "../CustomButton";

const ShareLink = ({ url, variantName, variantImage }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onCopyLink = () => {
    navigator?.clipboard?.writeText(url);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Share />}
        onClick={handleClickOpen}
        className="mt-5"
      >
        Share
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box className="bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded outline-none">
          <span className="flex items-center p-4 gap-3">
            <LazyLoadImage
              src={variantImage}
              alt=""
              className="h-14 w-14 rounded"
            />
            <span className="flex flex-col gap-1">
              <p className="text-sm font-semibold">{variantName}</p>
              <p className="text-xs">{url}</p>
            </span>
          </span>
          <Divider />
          <span className="flex gap-4 items-center justify-center p-3">
            <span
              className="shadow border items-center flex flex-col p-3 lg:cursor-pointer rounded-sm gap-1 w-[80px]"
              onClick={onCopyLink}
            >
              <ContentCopy className="!text-xl" />
              <p className="text-xs">Copy</p>
            </span>
            <WhatsappShareButton url={url}>
              <span className="shadow border items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]">
                <WhatsApp className="!text-xl text-[#00a884]" />
                <p className="text-xs">WhatsApp</p>
              </span>
            </WhatsappShareButton>
            <FacebookShareButton url={url}>
              <span className="shadow border items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]">
                <Facebook className="!text-xl text-[#1877F2]" />
                <p className="text-xs">Facebook</p>
              </span>
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <span className="shadow border items-center flex flex-col p-3 rounded-sm gap-1 w-[80px]">
                <Twitter className="!text-xl text-[#1CA1F2]" />
                <p className="text-xs">Twitter</p>
              </span>
            </TwitterShareButton>
          </span>
        </Box>
      </Modal>
    </>
  );
};
export default ShareLink;
