import axios from "axios";
import React, { useState } from "react";
import gtag_report_conversion from "../Shared/ContactReport";
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import {
    AccountCircle,
    Chat,
    Email,
    Facebook,
    Instagram,
    LinkedIn,
    Phone,
    Twitter,
    YouTube,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import ShareLink from "../Pages/Share";

const Form = () => {
    const [firstname, setFistName] = useState("");
    const [msg, setMsg] = useState("");
    const [lastname, setListName] = useState("");
    const [email1, setEmail1] = useState("");
    const [number, setNumber] = useState("");
    const [check, setcheck] = useState("explore");
    const reeact = (event) => {
        event.preventDefault();
        const reqbody = new FormData();
        reqbody.append("first_name", firstname);
        reqbody.append("last_name", lastname);
        reqbody.append("email", email1);
        reqbody.append("mobile_no", number);
        reqbody.append("customer_messages", msg);

        axios
            .post("https://b1.bhaaraterp.com/Api_data/add-contact-us/", reqbody, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    /*  token: "c0951c5b80621b873302bc6d68669bdfe7876f73", */
                    Authorization: "c0951c5b80621b873302bc6d68669bdfe7876f73",
                    // token: "4ab271b8d39d1beeefd47d3b1bf8130832e087eb",
                },
            })
            .then((response) => {
                alert(response.data.message);

                if (response.data.response_code === 200) {
                    gtag_report_conversion("https://aaragroups.com/");
                    // window.location.href = `https://crmapp.bhaaraterp.com/whatsapp?template=contact_form&name=${firstname + lastname}&mobile=${number}&email=${email1}&callback_url=https://aaragroups.com/&access_token=b8bb5f83ea8a4ab4886394dbd00dbd97`
                    window.location.href = `https://crmapp.bhaaraterp.com/whatsapp?template=training_career_form&name=${firstname + " " + lastname
                        }&mobile=${number}&email=${email1}&callback_url=https://aaragroups.com/&access_token=b8bb5f83ea8a4ab4886394dbd00dbd97`;
                }
                setFistName("");
                setListName("");
                setEmail1("");
                setMsg("");
                setNumber("");
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div>
            <div className="container flex flex-col items-center justify-center p-10">

                <div id="form" className="p-6 rounded-md shadow-md shadow-slate-600">
                    <p className="p-4 text-xl font-bold text-center">Explore/Registration Form</p>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={check}
                            onChange={(e) => setcheck(e.target.value)}
                            className="!font-bold"
                        >
                            <FormControlLabel
                                value="explore"
                                control={<Radio />}
                                className="!font-bold"
                                label="I want to Explore"
                            />
                            <FormControlLabel
                                value="register"
                                control={<Radio />}
                                label="I want to Register"
                            />
                        </RadioGroup>
                    </FormControl>

                    <div className="w-full bg-blue-500 h-[2px]"></div>
                    {check === "explore" ? (
                        <div>
                            <div className="grid gap-4 pt-8 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
                                <div className="">
                                    <AccountCircle className="mt-5" />
                                    &nbsp;
                                    <TextField
                                        id="outline-basic"
                                        label="First Name"
                                        required
                                        variant="standard"
                                        onChange={(e) => setFistName(e.target.value)}
                                        value={firstname}
                                    />
                                </div>
                                <div className="">
                                    <AccountCircle className="mt-5" />
                                    &nbsp;
                                    <TextField
                                        id="outline-basic"
                                        label="Last Name"
                                        variant="standard"
                                        onChange={(e) => setListName(e.target.value)}
                                        value={lastname}
                                    />
                                </div>
                            </div>
                            <div className="grid gap-4 pt-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
                                <div className="">
                                    <Email className="mt-5" />
                                    &nbsp;
                                    <TextField
                                        id="outline-basic"
                                        label="Email Address"
                                        variant="standard"
                                        onChange={(e) => setEmail1(e.target.value)}
                                        value={email1}
                                    />
                                </div>
                                <div className="">
                                    <Phone className="mt-5" />
                                    &nbsp;
                                    <TextField
                                        id="outline-basic"
                                        label="Phone Number"
                                        required
                                        variant="standard"
                                        onChange={(e) => setNumber(e.target.value)}
                                        value={number}
                                    />
                                </div>
                            </div>

                            <div className="flex gap-1 pt-4 my-5">
                                <div>
                                    <Chat />
                                </div>
                                <div>
                                    <p>Tell Us. We Are Listening</p>
                                </div>
                            </div>
                            <div className="">
                                <input
                                    onChange={(e) => setMsg(e.target.value)}
                                    value={msg}
                                    type="text"
                                    className="w-full p-4 shadow-sm outline-none h-28 bg-slate-100 shadow-slate-300 "
                                    placeholder="Text..."
                                />
                            </div>
                            <div className="flex justify-center w-full mt-2">
                                <div className="text-center ">
                                    <button
                                        onClick={reeact}
                                        type="submit"
                                        className="px-6 py-2 text-lg text-white rounded-md bg-slate-700 "
                                    >
                                        SUBMIT{" "}
                                    </button>
                                </div>
                            </div>
                            <div className="mt-2 ">
                                <p className="">
                                    By providing your contact details, you agree to our{" "}
                                    <u>Terms of Use</u> & <u>Privacy Policy</u>{" "}
                                </p>
                            </div>
                            <div className="grid text-right sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
                                {" "}
                                <div className="flex justify-end col-span-2 col-start-2 space-x-3 ">
                                    <ShareLink
                                        url={window.location.href}


                                    />
                                    <Link to="https://www.facebook.com/aaragroups">
                                        {" "}
                                        <p className="">
                                            <Facebook className="hover:text-sky-400" />
                                        </p>{" "}
                                    </Link>{" "}
                                    <Link to="https://www.instagram.com/aaragroups/">
                                        {" "}
                                        <p className="">
                                            <Instagram className="hover:text-sky-400" />
                                        </p>
                                    </Link>
                                    <Link to="https://twitter.com/aaragroups">
                                        <p>
                                            <Twitter className="hover:text-sky-400" />
                                        </p>
                                    </Link>{" "}
                                    <Link to="https://www.linkedin.com/company/74523237/">
                                        {" "}
                                        <p>
                                            <LinkedIn className="hover:text-sky-400" />
                                        </p>
                                    </Link>
                                    <Link to="https://www.youtube.com/@AARATechnologiesPvtLtd">
                                        <p>
                                            <YouTube className="hover:text-sky-400" />
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="">
                            <div className="text-4xl text-center text-white">
                                <b>
                                    <p className="">Register Now</p>
                                </b>
                            </div>
                            <div className="mt-4 text-base text-center text-gray-500 ">
                                <b>
                                    <p>
                                        A COMPANY WORK WITH YOUNG ENGINEER'S,ENTERPRENEUR'S AND
                                        INNOVATIVE TEAM
                                    </p>
                                </b>
                            </div>

                            <form action="https://rzp.io/l/LFSC7SyDu">
                                <div className="col-span-2 p-5 bg-white rounded-xl ">
                                    <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Student Full Name </b>
                                            </label>
                                            <input
                                                required
                                                type="text"
                                                placeholder="Enter Student Full Name "
                                                className="p-1 mt-2 border-2 outline-none border-slate-300"
                                            />
                                        </div>
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Student Father's Name</b>
                                            </label>
                                            <input
                                                required
                                                type="text"
                                                placeholder="Enter Student Father's Name"
                                                className="p-1 mt-2 border-2 outline-none border-slate-300"
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Choose Training</b>
                                            </label>

                                            <select
                                                name="cars"
                                                id="cars"
                                                className="p-1 mt-2 border-2 outline-none border-slate-300"
                                                required
                                            >
                                                <option value="1">-Choose Training-</option>
                                                <option value="2">Online Summer Training</option>
                                                <option value="3">Vocation Training</option>
                                                <option value="4">Summer Training</option>
                                                <option value="5">Winter Training</option>
                                                <option value="6">Industrial Training</option>
                                                <option value="7">Internship Training</option>
                                                <option value="8">Project Training</option>
                                            </select>
                                        </div>
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Choose Technology</b>
                                            </label>
                                            <select
                                                name="cars"
                                                id="cars"
                                                className="p-1 mt-2 border-2 outline-none border-slate-300"
                                                required
                                            >
                                                <option value="1">-Choose Technology-</option>
                                                <option value="2">Full Stack</option>
                                                <option value="2">Cloud-Computing</option>
                                                <option value="2">DevOps</option>
                                                <option value="2">Aws</option>
                                                <option value="2">PHP</option>
                                                <option value="3">React js</option>
                                                <option value="4">Python</option>
                                                <option value="5">Java</option>
                                                <option value="6">Node js</option>
                                                <option value="7">Digital Marketing</option>
                                                <option value="8">Mobile App & Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Select Your Education </b>
                                            </label>
                                            <select
                                                name="cars"
                                                id="cars"
                                                className="p-1 mt-2 border-2 outline-none border-slate-300"
                                                required
                                            >
                                                <option value="1">-Select Your Education-</option>
                                                <option value="2">B.Tech(IT) </option>
                                                <option value="2">B.Tech(Electronics)</option>
                                                <option value="2">B.Tech(EC)</option>
                                                <option value="2">B.Tech(CS)</option>
                                                <option value="2">Diploma(CS)</option>
                                                <option value="3">Diploma(IT)</option>
                                                <option value="4">Diploma(Electronics)</option>
                                                <option value="5">BCA</option>
                                                <option value="6">MCA</option>
                                                <option value="7">M.Tech(CS)</option>
                                                <option value="8">M.Tech(IT)</option>
                                                <option value="8">Other</option>
                                            </select>
                                        </div>
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Select Year</b>
                                            </label>
                                            <select
                                                name=""
                                                id=""
                                                className="p-1 mt-2 border-2 outline-none border-slate-300"
                                                required
                                            >
                                                <option value="1">-Select Year-</option>
                                                <option value="2">First Year(1st)</option>
                                                <option value="3">Second Year(2nd)</option>
                                                <option value="4">Third Year(3rd)</option>
                                                <option value="5">Final Year(4th)</option>
                                                <option value="6">Complete</option>
                                                <option value="7">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Student Phone Number</b>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Student Phone Number"
                                                className="p-1 mt-2 border-2 outline-none border-slate-300 "
                                            />
                                        </div>
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Student Email ID</b>
                                            </label>
                                            <input
                                                required
                                                type="text"
                                                placeholder="Enter Student Email ID"
                                                className="p-1 mt-2 border-2 outline-none border-slate-300 "
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Student Alternate Mobile (Optional)</b>
                                            </label>
                                            <input
                                                required
                                                type="text"
                                                placeholder="Enter Student Mobile Number"
                                                className="p-1 mt-2 border-2 outline-none border-slate-300"
                                            />
                                        </div>
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Student College Name</b>
                                            </label>
                                            <input
                                                required
                                                type="text"
                                                placeholder="Enter Student College Name"
                                                className="p-1 mt-2 border-2 outline-none border-slate-300 "
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>
                                                    <p>Payment Type</p>
                                                </b>
                                            </label>
                                            <div className="mt-3">
                                                <input required type="checkbox" />

                                                <b>Registration Fee</b>
                                            </div>
                                        </div>
                                        <div className="flex flex-col ">
                                            <label htmlFor="">
                                                <b>Amount To Pay Now</b>
                                            </label>
                                            <input
                                                type="text"
                                                value={500}
                                                className="p-1 mt-2 border-2 outline-none bg-slate-300 border-slate-300 "
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-5 text-center">
                                        <button
                                            type="text"
                                            className="p-2 px-4 text-lg text-white rounded-md bg-slate-700"
                                        >
                                            <b>Register Now</b>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Form;
