var dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }


function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-310897006/4hyNCMf3vukYEO7Sn5QB',
      // 'event_callback': callback
  });
  return false;
}
  
  export default gtag_report_conversion;
  